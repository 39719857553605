import { SearchOrder } from "../../models/search-options.model";
import { SharedState, urlQueryKeys } from "../../sharedState/SharedState";
import { StateModifier } from "../StateModifier";

/***
 * Returns a modified SharedState.
 * 
 *  * Params:
 *          type: the property type
 *          name: the property name
 *          direction: the new search order
 */
export class SearchOrderStateModifier implements StateModifier {
    public modify(sharedState: SharedState, params: { [name: string]: string }): SharedState {
        const nextSharedState: SharedState = sharedState.clone();

        let shortType: string = "";
        switch (params.type) {
            case "system":
                shortType = urlQueryKeys.systemProperties;
                break;
            case "custom":
                shortType = urlQueryKeys.customProperties;
                break;
            case "validity":
                shortType = urlQueryKeys.validity;
                break;
        }

        let shortDirection: "1" | "-1" = (params.direction === "descending") ? "-1" : "1";

        if (shortType) {
            const order: SearchOrder = { propertyName: params.name, propertyType: shortType, direction: shortDirection };
            nextSharedState.filters.searchOptions.order.push(order);
        }
        return nextSharedState;
    }
}

export const searchOrderStateModifier = new SearchOrderStateModifier();
