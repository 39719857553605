import { PropertyValue } from "../models/metadata.model";
import { SourceItem } from "../models/source-item.model";
import { SharedState } from "../sharedState/SharedState";

/***
 * Loads a cart from local storage.
 */
export function loadCart(site: string): Array<SourceItem> {
    return JSON.parse(localStorage?.getItem(`${site}.download-cart`) || "[]");
}

/***
 * Saves a cart to local storage.
 */
export function saveCart(cart: Array<SourceItem>, site: string): void {
    localStorage.setItem(`${site}.download-cart`, JSON.stringify(cart));
}

/***
 * Builds a cart item based on the current state.
 * The state includes the path and the metadata for the current file, and the current filters.
 */
export function buildCartItem(state: SharedState): SourceItem | null {
    if (state.path) {
        const item: SourceItem = {
            value: state.path,
            props: {
                system: {
                    title: [state.metadata?.title || ""],
                },
                other: {
                    validityFilter: [state.filters.validity || ""],
                }
            },
        };
        state.filters.system.lang?.[0]?.toString() && (item.props.system.lang = [state.filters.system.lang?.[0]?.toString()]);
        state.filters.system.edition?.[0]?.toString() && (item.props.system.edition = [state.filters.system.edition?.[0]?.toString()]);
        return item;
    }
    return null;
}