import { memo, useEffect, useState } from "react";
import { SharedState } from "../sharedState/SharedState";
import { SourceItem } from "../models/source-item.model";
import { LocalizeValueModifier } from "../source/valuemodifier/LocalizeValueModifier";
import { CallbackButton } from "./CallbackButton";

/***
 * Displays a download cart.
 * The cart shows a list of all files that will be downloaded when the user clicks the download button,
 * and a download button.
 * This component is currently an exception to the rule that all HTML content are provided by the
 * customer. It may be refactored in the future.
 */
export const DownloadCart = memo(function DownloadCartComponent(props: {
    sharedState: SharedState,
    indexfile: string,
    wrapperfile: string,
}): JSX.Element {

    const [cart, setCart] = useState<Array<SourceItem>>([]);

    const localizer = new LocalizeValueModifier();

    // Load the cart in an effect hook so the initial render is same as server-side render
    useEffect(() => {
        if (props.sharedState.isClient) {
            setCart(props.sharedState.cart);
        }
    }, [props.sharedState]);

    const getStateForItem = (item: SourceItem, sharedState: SharedState): SharedState => {
        const newState = new SharedState(sharedState);
        newState.path = item.value.toString();
        newState.filters.system = Object.assign({}, item.props.system);
        delete newState.filters.system["path"];
        delete newState.filters.system["title"];
        newState.filters.validity = item.props.other?.validityFilter?.[0]?.toString() || "";
        return newState;
    }

    const validityProps: Array<string> = [];
    const systemProps: Array<string> = [];
    for (let item of cart) {
        const systemPropNames = Object.keys(item.props.system || {});
        for (let systemPropName of systemPropNames) {
            if (!systemProps.includes(systemPropName)) {
                systemProps.push(systemPropName);
            }
        }
        const validityPropNames = Object.keys(item.props.validity || {});
        for (let validityPropName of validityPropNames) {
            if (!validityProps.includes(validityPropName)) {
                validityProps.push(validityPropName);
            }
        }
    }

    const columns: Array<{ label: string, id: string, classExtensions: Array<string>, renderCell: (item: SourceItem) => string | JSX.Element }> = [
        {
            label: localizer.modify(props.sharedState, 'Path', {}),
            id: "system-path",
            classExtensions: ["path"],
            renderCell: (item: SourceItem) => {
                const itemState = getStateForItem(item, props.sharedState);
                const urlQuery = itemState?.getUrlQuery();
                const urlQueryStr = urlQuery ? "?" + urlQuery : "";
                return <a href={props.sharedState.backend.getAppLink(props.sharedState.sitePath || "", item.value.toString(),
                    item.props.other.validityFilter?.[0]?.toString() || "",
                    item.props.system) + urlQueryStr}>{item.value.toString()}</a>
            }
        },
        {
            label: localizer.modify(props.sharedState, 'Title', {}),
            id: "system-title",
            classExtensions: ["title"],
            renderCell: (item: SourceItem) => item.props.system?.title?.[0]?.toString() || "",
        },
        ...systemProps.filter(propName => { return (propName !== "path" && propName !== "title") }).map(
            propName => ({
                label: localizer.modify(props.sharedState, `${propName}`, {}),
                id: "system-" + propName,
                classExtensions: ["property", "property_" + propName],
                renderCell: (item: SourceItem) => localizer.modify(props.sharedState
                    , item.props.system?.[propName]?.[0]?.toString() || "", {}),
            })),
        ...validityProps.map(
            propName => ({
                label: localizer.modify(props.sharedState, `${propName}`, {}),
                id: "validity-" + propName,
                classExtensions: ["property", "property_" + propName],
                renderCell: (item: SourceItem) => localizer.modify(props.sharedState, item.props.validity?.[propName]?.[0]?.toString() || "", {}),
            })),
        {
            label: "",
            id: "remove-button",
            classExtensions: ["remove_button"],
            renderCell: (item: SourceItem) => <CallbackButton
                sharedState={getStateForItem(item, props.sharedState)}
                label={localizer.modify(props.sharedState, `Remove from cart`, {})}
                callback={"remove-from-cart"}
                requireValidForm={false}
                content={[]}
            />
        }
    ];

    const localizedButtonText = localizer.modify(props.sharedState, "Download", {});

    if (!cart.length) {
        return <div className="_download">
            <div className="_download_empty"></div>
        </div>
    }
    else {
        return <div className="_download">
            <div className="_download_buttons _download_buttons_before">
                <p className="_download_link"><a href={props.sharedState.backend.getDownloadLink(
                    props.sharedState.site?.name || "",
                    props.sharedState.filters.slot || "production",
                    cart,
                    props.wrapperfile,
                    props.indexfile
                )}><button className="_download_button _download_button_before" type="button">{localizedButtonText}</button></a></p>
            </div>
            <div className="_download_labels">
                {columns.map((column, ix) => (
                    <p className={"_download_label " + column.classExtensions.map(ext => "_download_label_" + ext).join(" ")} key={column.id}>{column.label}</p>
                ))}
            </div>
            <div className="_download_items">
                {cart.map((item, ix) => {
                    // Build unique key for this item
                    const key: string = item.value.toString()
                        + item.props.other.validityFilter || ""
                        // + Object.entries(item.props.validity).map(keyvalue => { return keyvalue[0] + ":" + keyvalue[1][0] }).join("-")
                        + Object.entries(item.props.system).map(keyvalue => { return keyvalue[0] + ":" + keyvalue[1][0] }).join("-");
                    return <div className="_download_item" key={key}>
                        {columns.map((column, ix) => (
                            <div className={"_download_cell " + column.classExtensions.map(ext => "_download_cell_" + ext).join(" ")} key={column.id}>
                                <p className="_download_cell_label">{column.label}</p>
                                <p className="_download_cell_content">{column.renderCell(item)}</p>
                            </div>
                        ))}
                    </div>
                })}
                <div className="_download_buttons _download_buttons_after">
                    <p className="_download_link"><a href={props.sharedState.backend.getDownloadLink(
                        props.sharedState.site?.name || "",
                        props.sharedState.filters.slot || "production",
                        cart,
                        props.wrapperfile,
                        props.indexfile
                    )}><button className="_download_button _download_button_after" type="button">{localizedButtonText}</button></a></p>
                </div>
            </div>
        </div>
    }
});