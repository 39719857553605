import { memo, useEffect, useRef, useState } from 'react';
import { SharedState } from '../sharedState/SharedState';
import { buildFromHtmlSource } from '../component-lists/helpers';
import { useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import { loadCart } from '../utils/cart.utils';

/***
 * This is the top level component that renders the entire site.
 */
export const SiteRoot = memo(function SiteRoot(props: {
  indexcontent: string,
  state: SharedState,
}) {

  const useLocationOnClient = props.state.isClient ? useLocation : () => { return { pathname: "", search: "" } };
  const location = useLocationOnClient();

  const [inIframe, setInIframe] = useState<boolean>(false);

  const [url, setUrl] = useState<string | null>(null);
  const [content, setContent] = useState<string | null>(props.state.content);

  /***
   * Find out if the site is embedded in an iframe.
   * This is used to apply different styles to the site when it is embedded.
   * Run this as an effect so it can run on the client only.
   */
  useEffect(() => {
    if (props.state.isClient) {
      setInIframe(window.self !== window.top);
    }
  }, [props.state.isClient]);

  const stateRef = useRef<SharedState>(props.state);

  useEffect(() => {
    let path: string = props.state.sitePath ? location.pathname.replace(props.state.sitePath, "") : location.pathname;
    if (!path || path === "/") {
      path = props.state.site?.files?.onlineStartPage?.path || "/home.html";
    }
    const url = props.state.backend.getDirectLink(props.state.site?.name || "",
      props.state.filters.slot || "production", path, props.state.filters.validity,
      props.state.filters.system);
    setUrl(url);
  }, [location.pathname, props.state.site, props.state.filters.slot, props.state.filters.validity, props.state.filters.system, props.state.backend, props.state.sitePath]);

  useEffect(() => {
    if (url) {
      async function loadDoc() {
        if (url) {
          const html: string = await props.state.backend.getTextfile(url);
          setContent(html);
        }
      }
      loadDoc();
    }
  }, [url, props.state.backend]);

  if (props.state.isClient) {
    const nextState = props.state.clone();
    // Update the state from the query string
    nextState.updateFromQuery(decodeURI(location.search), props.state.settings);

    // Add cart to settings
    nextState.cart = loadCart(props.state.site?.name || "unknown");

    // Set content, reflecting current url, in state
    nextState.content = content;

    if (!isEqual(nextState, stateRef.current)) {
      stateRef.current = nextState;
    }

  }

  const pagecontent = buildFromHtmlSource(props.indexcontent, stateRef.current);

  return <div
    id={"__scdp_siteroot_component"}
    className={"_site _slot-" + (props.state.filters.slot || "production") + (inIframe ? " embedded" : " standalone")}>
    {pagecontent || ""}
  </div>
});