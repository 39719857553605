import { Metadata } from "../../../models/metadata.model";
import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { mapMetadataToItem } from "../../../utils/source-item.utils";
import { ArraySource } from "../../ArraySource";

/***
 * Returns all metadata objects that match the current filter but with the validity
 * specified by a baseline defined in sharedState.
 * 
 * Params:
 *      format: The content type of sought files. May be empty.
 */
export class QueryBaselineSource implements ArraySource {
    public async getValue(sharedState: SharedState, params: { [name: string]: string }): Promise<Array<SourceItem>> {
        const contentTypeFilter = params.format ? { contentType: params.format } : {};
        const baselineState = sharedState.clone();
        baselineState.filters.validity = sharedState.diffBase;
        const searchlist: Array<Metadata> = sharedState.site?.name ? await sharedState.backend.search(
            sharedState.site.name, sharedState.organization, sharedState.configuration, { ...baselineState.filters, ...contentTypeFilter }) : [];
        return searchlist.map((hit, ix) => {
            return { state: baselineState, ...mapMetadataToItem(hit), position: ix + 1 };
        });
    }
}