import { PropertyValue } from "../../models/metadata.model";
import { SharedState } from "../../sharedState/SharedState";
import { suppressUrlParameters } from "../../utils/link.utils";
import { ValueModifier } from "../ValueModifier";

/***
 * Formats a path into a link to the file on the server.
 * A direct link exposes the API for downloading the file.
 * 
 * Params:
 *     suppress: specification of what parts in the SharedState to suppress (not include) in the link
 */
export class LinkDirectValueModifier implements ValueModifier {
    public modify(sharedState: SharedState, value: PropertyValue | null, params: { [name: string]: string }) {
        const path = (value || "").toString();
        const linkedState = suppressUrlParameters(sharedState, params.suppress || "");

        const link = sharedState.backend.getDirectLink(linkedState.site?.name || "", linkedState.filters.slot || "", path,
            linkedState.filters.validity, linkedState.filters.system);
        return link
    }
}