import { memo } from 'react';
import { SharedState } from "../sharedState/SharedState";
import { Link } from 'react-router-dom';
import { buildContent } from '../component-lists/helpers';

/***
 * A component that renders a rating.
 * This is currently not functional but is here for demo purposes.
 */
export const AppLink = memo(function AppLink(props: {
  sharedState: SharedState,
  to: string,
  content: Array<Node>,
}): JSX.Element {

  return (
    <Link to={props.to}>{buildContent(props.sharedState, props.content, "StoreItem", [])}</Link>
  );
});