import { SharedState, getLocationItem } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns the current URL.
 * 
 * Params: (none)
 */
export class LocationItemSource implements ArraySource {

    public async getValue(sharedState: SharedState, params: { [name: string]: string }) {
        return [getLocationItem(sharedState)];
    };
}