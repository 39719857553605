import { Metadata, PropertiesModel } from "../models/metadata.model";
import qs from "qs";
import { Terms } from "../models/terms.model";
import { Settings } from "../models/settings.model";
import { SourceItem } from "../models/source-item.model";
import { Backend } from "./backend";
import { Configuration } from "../models/configuration.model";
import { Organization } from "../models/organization.model";
import { FilterModel } from "../models/filter.model";
import { Site } from "../models/site.model";
import { Batch } from "../models/batch.model";
import { Theme } from "../models/theme.model";

/***
 * This is the standard backend implementation that uses the API server to get data.
 */
export class ApiBackend implements Backend {

    serverUrl = process.env.REACT_APP_API_URL || "/app";

    public getName = (): string => {
        return "API";
    }

    public getTextfile = async (
        url: string,
    ): Promise<string> => {
        const res = await fetch(url);
        return res.status === 200 ? res.text() : "";
    }

    public getIdLink = (
        id: string,
    ) => {
        return `${this.serverUrl}/file/${id}`;
    }

    public getDirectLink = (
        site: string,
        slot: string | null,
        path: string,
        validity: string,
        systemProperties: PropertiesModel,
    ): string => {
        const pathWithLeadingSlash = path.startsWith("/") ? path : "/" + path;
        const urlParams: { [key: string]: any } = {};
        if (slot) {
            urlParams.slot = slot;
        }
        if (validity.length) {
            urlParams.v = validity;
        }
        if (Object.keys(systemProperties).length) {
            urlParams.s = systemProperties;
        }
        const urlParamsStr = qs.stringify(urlParams);
        let link = `${this.serverUrl}/file/${site || ''}${pathWithLeadingSlash}`
        if (urlParamsStr) {
            link += `?${urlParamsStr}`;
        }
        return link;
    }

    public getAppLink = (
        sitePath: string,
        path: string,
        _validity: string,
        _systemProperties: PropertiesModel,
    ): string => {
        const pathWithLeadingSlash = path.startsWith("/") ? path : "/" + path;
        return `${sitePath}${pathWithLeadingSlash}`;
    }

    public search = async (
        site: string,
        org: string | null,
        cfg: string | null,
        filters: FilterModel,
        refreshCache?: boolean,
        unpublished?: boolean,
    ): Promise<Array<Metadata>> => {
        const res = await fetch(`${this.serverUrl}/search/metadata/${site}${this.getUrlQueryInfo(org, cfg, filters)}${unpublished ? "&unpublished=true" : ""}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
            cache: refreshCache ? "reload" : "default",
        });
        if (res.status === 200) {
            return res.json().then((jsonObj) => {
                this.walkJson(jsonObj, (parent: { [key: string]: any }, key: string, value: any) => {
                    if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value)) {
                        // Express server sends dates in ISO string format. To get the real date values back we parse
                        // any such string into Date
                        parent[key] = new Date(Date.parse(value));
                    }
                });
                return jsonObj;
            });
        }
        else {
            return [];
        }
    }

    public getPropertyValueSet = async (
        site: string,
        org: string | null,
        cfg: string | null,
        filters: FilterModel,
        propertyName: string,
        isValidity: boolean,
        isSystemProperty: boolean,
    ): Promise<Array<SourceItem>> => {
        const res = await fetch(`${this.serverUrl}/search/valueset/${site}/${propertyName}${this.getUrlQueryInfo(org, cfg, filters)}&isValidity=${isValidity}&isSystemProperty=${isSystemProperty}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const items: Array<SourceItem> = res.status === 200 ? await res.json() : null;
        if (items) {
            return items.map((obj, ix) => { return { ...obj, position: ix + 1 } });
        }
        return [];
    }

    public getPropertyNames = async (
        site: string,
        org: string | null,
        cfg: string | null,
        filters: FilterModel,
    ): Promise<Array<SourceItem>> => {
        const res = await fetch(`${this.serverUrl}/search/propertynames/${site}${this.getUrlQueryInfo(org, cfg, filters)}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const items: Array<SourceItem> = res.status === 200 ? await res.json() : null;
        if (items) {
            return items.map((obj, ix) => { return { ...obj, position: ix + 1 } });
        }
        return [];
    }

    public getTerms = async (
        site: string,
        slot: string | null,
    ): Promise<Terms> => {
        const res = await fetch(`${this.serverUrl}/terms/${site}?${slot ? '&slot=' + slot : ''}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.status === 200 ? res.json() : null;
    }

    public getConfiguration = async (
        site: string,
        slot: string | null,
        id: string,
    ): Promise<Configuration> => {
        const res = await fetch(`${this.serverUrl}/configuration/${site}/${id}?${slot ? '&slot=' + slot : ''}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.status === 200 ? res.json() : null;
    }

    public getOrganization = async (
        site: string,
        slot: string | null,
        id: string,
    ): Promise<Organization> => {
        const res = await fetch(`${this.serverUrl}/organization/${site}/${id}?${slot ? '&slot=' + slot : ''}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.status === 200 ? res.json() : null;
    }

    public getSettings = async (
        site: string,
        slot: string | null,
        orgId: string | null,
        cfgId: string | null,
    ): Promise<Settings> => {
        const res = await fetch(`${this.serverUrl}/settings/${site}?${slot ? '&slot=' + slot : ''}${orgId ? '&org=' + orgId : ''}${cfgId ? '&cfg=' + cfgId : ''}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.status === 200 ? res.json() : null;
    }

    public submitForm = async (
        site: string,
        name: string,
        data: { [key: string]: string | boolean },
    ): Promise<{ success: boolean, message: string | null }> => {
        return await fetch(`${this.serverUrl}/submit/${site}/${name}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((res: Response) => {
            return res.json();
        }).catch((err: Error) => {
            return { success: false, message: err.message };
        });
    }

    private walkJson(jsonObj: any, callback: (parent: object, key: string, value: any) => void) {
        for (var key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
                var value = jsonObj[key];
                callback(jsonObj, key, value);
                if (typeof value === "object" && value !== null) {
                    this.walkJson(value, callback);
                }
            }
        }
    }

    private getUrlQueryInfo(org: string | null, cfg: string | null, filters: FilterModel) {
        const cleanedFilters: { [key: string]: any } = { ...filters };
        for (const key in cleanedFilters) {
            if (cleanedFilters.hasOwnProperty(key)) {
                if (cleanedFilters[key] === null || cleanedFilters[key] === undefined || cleanedFilters[key] === "") {
                    delete cleanedFilters[key];
                }
            }
        }
        return "?filters=" + JSON.stringify(cleanedFilters) + (org ? "&org=" + org : "") + (cfg ? "&cfg=" + cfg : "");
    }

    public getDownloadLink = (
        site: string,
        slot: string | null,
        items: Array<SourceItem>,
        wrapperfile: string | null,
        indexfile: string | null
    ): string => {
        return `${this.serverUrl}/download/${site || ''}/${slot || ''}?items=${JSON.stringify(items)}${wrapperfile ? '&wrapperfile=' + wrapperfile : ''}${indexfile ? '&indexfile=' + indexfile : ''}`;
    };

    public getMetadata = async (
        id: string,
    ): Promise<Metadata | null> => {
        const res = await fetch(`${this.serverUrl}/file/metadata/${id}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.status === 200 ? res.json() : null;
    }

    public saveMetadata = async (
        id: string,
        metadata: Metadata,
    ): Promise<{ success: boolean, message: string }> => {
        const res = await fetch(`${this.serverUrl}/file/${id}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(metadata),
        });
        return res.json();
    }

    public deleteMetadata = async (
        id: string,
    ): Promise<{ success: boolean, message: string }> => {
        const res = await fetch(`${this.serverUrl}/file/${id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.json();
    }

    public getSite = async (
        name: string,
        refreshCache?: boolean,
    ): Promise<Site | null> => {
        const res = await fetch(`${this.serverUrl}/site/${name}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            cache: refreshCache ? "reload" : "default",
        });
        return res.status === 200 ? res.json() : null;
    }

    public getSites = async () => {
        const res = await fetch(`${this.serverUrl}/site`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.status === 200 ? res.json() : null;
    }

    public getSlots = async (
        site: string,
    ): Promise<Array<SourceItem>> => {
        const res = await fetch(`${this.serverUrl}/search/slots/${site}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.status === 200 ? await res.json() : [];
    }

    public getBatch = async (
        batchid: string,
        refreshCache?: boolean,
    ): Promise<Batch | null> => {
        const res = await fetch(`${this.serverUrl}/batch/${batchid}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            cache: refreshCache ? "reload" : "default",
        });
        return res.status === 200 ? await res.json() : null;
    }

    public getBatches = async (
        site: string,
        slot: string,
        refreshCache?: boolean,
    ): Promise<Array<Batch>> => {
        const res = await fetch(`${this.serverUrl}/batch/${site}/${slot}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            cache: refreshCache ? "reload" : "default",
        });
        return res.status === 200 ? await res.json() : [];
    }

    public createBatch = async (
        batch: Batch,
    ): Promise<{ success: boolean, message: string }> => {
        const res = await fetch(`${this.serverUrl}/batch/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(batch),
        });
        return res.json();
    }

    public publishBatch = async (
        site: string,
        slot: string,
        batchid: string,
        dryrun: boolean,
        preview: boolean,
    ): Promise<Array<Metadata>> => {
        const res = await fetch(`${this.serverUrl}/batch/publish/${site}/${slot}/${batchid}?dryrun=${dryrun}&preview=${preview}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({}),
        });
        return res.status === 200 ? (await res.json() as { metadata: Array<Metadata> }).metadata : [];
    }

    public patchSite = async (
        id: string,
        changes: { [key: string]: any },
    ): Promise<{ success: boolean, message: string }> => {
        const res = await fetch(`${this.serverUrl}/site/${id}`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(changes),
        });
        return res.json();
    }

    public saveSite = async (
        siteid: string,
        site: Site,
    ): Promise<{ success: boolean, message: string }> => {
        const res = await fetch(`${this.serverUrl}/site/${siteid}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(site),
        });
        return res.json();
    }


    public uploadFile = async (
        site: string,
        slot: string,
        path: string,
        file: File,
    ): Promise<{ success: boolean, message: string }> => {
        // const formData = new FormData();
        // formData.append('file', file);
        const data = new Blob([file], { type: file.type });
        const res = await fetch(`${this.serverUrl}/file/upload/${site}/${slot}${path}`, {
            method: "POST",
            body: data,
        });
        return res.json();
    }

    public uploadZipToBatch = async (
        batchId: string,
        file: File,
    ): Promise<{ success: boolean; message: string; }> => {
        const res = await fetch(`${this.serverUrl}/batch/${batchId}`, {
            method: "POST",
            duplex: 'half',
            body: file,
        } as any);
        return res.json();
    }

    public saveTerms = async (
        site: string,
        slot: string,
        terms: Terms,
    ): Promise<{ success: boolean, message: string }> => {
        const res = await fetch(`${this.serverUrl}/terms/${site}/${slot}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(terms.terms),
        });
        return res.json();
    }

    public getTheme = async (
        site: string,
        themeid: string,
        refreshCache?: boolean,
    ): Promise<Theme | null> => {
        const res = await fetch(`${this.serverUrl}/theme/${site}/${themeid}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            cache: refreshCache ? "reload" : "default",
        });
        return res.status === 200 ? await res.json() : null;
    }

    public getThemes = async (
        site: string,
        refreshCache?: boolean,
    ): Promise<Array<Theme>> => {
        const res = await fetch(`${this.serverUrl}/theme/site/${site}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            },
            cache: refreshCache ? "reload" : "default",
        });
        return res.status === 200 ? await res.json() : [];
    }

    public createTheme = async (
        site: string,
        name: string,
        template: string | null,
    ): Promise<{ success: boolean, message: string }> => {
        const res = await fetch(`${this.serverUrl}/theme/${site}/${name}${template ? '?template=' + template : ''}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.json();
    }

    public deleteTheme = async (
        site: string,
        themeid: string,
    ): Promise<{ success: boolean, message: string }> => {
        const res = await fetch(`${this.serverUrl}/theme/${site}/${themeid}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.json();
    }

    public updateTheme = async (
        site: string,
        themeid: string,
        update: any,
    ): Promise<{ success: boolean, message: string }> => {
        const res = await fetch(`${this.serverUrl}/theme/${site}/${themeid}`, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(update),
        });
        return res.json();
    }

    public saveTheme = async (
        site: string,
        themeid: string,
        theme: Theme,
    ): Promise<{ success: boolean, message: string }> => {
        const res = await fetch(`${this.serverUrl}/theme/${site}/${themeid}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(theme),
        });
        return res.json();
    }

    public activateTheme = async (
        siteid: string,
        themeid: string,
    ): Promise<{ success: boolean, message: string }> => {
        const res = await fetch(`${this.serverUrl}/theme/activate/${siteid}/${themeid}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
        });
        return res.json();
    }

}
