import { PropertyValue } from "../../models/metadata.model";
import { SharedState } from "../../sharedState/SharedState";
import { ValueModifier } from "../ValueModifier";

/***
 * Localizes the value by lloking it up in the terms list.
 * 
 *  Params:
 *          lang: localize to a particular language, may be omitted to use the current language
 */
export class LocalizeValueModifier implements ValueModifier {
    public modify(sharedState: SharedState, value: PropertyValue | null, params: { [name: string]: string }) {
        const lang = params.lang || (sharedState.filters.system.lang ? sharedState.filters.system.lang[0].toString() : "en");
        const term = value?.toString() || "";
        // First look up the term in the theme terms
        if (lang && sharedState.theme?.terms) {
            const termDef = sharedState.theme.terms.find((termDef) => termDef.key === term);
            if (termDef) {
                const langTerm = termDef.langs.find((langTerm) => langTerm.lang === lang);
                if (langTerm) {
                    return langTerm.value;
                }
            }
        }
        // If not found in the theme terms, look up in the shared terms
        if (lang && sharedState.terms?.[term]?.[lang]) {
            return sharedState.terms[term][lang];
        }
        return term;
    }
}