/***
 * State modifiers can change various aspects of the state.
 * They can be used on parts of a page, for instance where searches should be restricted to a subset
 * based on some predefined condition.
 */

import { SharedState } from "../sharedState/SharedState";
import { baselineStateModifier } from "./statemodifier/BaselineStateModifier";
import { configurationStateModifier } from "./statemodifier/ConfigurationStateModifier";
import { customStateModifier } from "./statemodifier/CustomStateModifier";
import { displayStateModifier } from "./statemodifier/DisplayStateModifier";
import { organizationStateModifier } from "./statemodifier/OrganizationStateModifier";
import { searchOrderStateModifier } from "./statemodifier/SearchOrderStateModifier";
import { systemStateModifier } from "./statemodifier/SystemStateModifier";
import { validityStateModifier } from "./statemodifier/ValidityStateModifier";

export type StateModifier = {
    modify: (sharedState: SharedState, params: { [name: string]: string }) => SharedState;
}

export type StateModifierRequest = {
    modifier: StateModifier | null;
    params: { [name: string]: string };
}

export const stateModifierMap: Array<{ modifier: StateModifier, regex: RegExp }> = [
    {
        modifier: configurationStateModifier,
        regex: /configuration\.(?<change>.*)\.(?<value>.*)/,
    },
    {
        modifier: customStateModifier,
        regex: /custom\.(?<change>.*)\.(?<name>.*)(?<!\\)\.(?<value>.*)/,
    },
    {
        modifier: displayStateModifier,
        regex: /display\.(?<change>.*)\.(?<name>.*)(?<!\\)\.(?<value>.*)/,
    },
    {
        modifier: organizationStateModifier,
        regex: /organization\.(?<change>.*)\.(?<value>.*)/,
    },
    {
        modifier: searchOrderStateModifier,
        regex: /searchorder\.(?<type>.*)\.(?<name>.*)(?<!\\)\.(?<direction>.*)/,
    },
    {
        modifier: systemStateModifier,
        regex: /system\.(?<change>.*)\.(?<name>.*)(?<!\\)\.(?<value>.*)/,
    },
    {
        modifier: validityStateModifier,
        regex: /validity\.(?<filter>.*)/,
    },
    {
        modifier: baselineStateModifier,
        regex: /baseline\.(?<filter>.*)/,
    },
]

function removeProtect(params: { [key: string]: string }): { [key: string]: string } {
    const result: { [key: string]: string } = {};
    for (const key in params) {
        result[key] = params[key]?.replaceAll("\\$", "$").replaceAll("\\;", ";").replaceAll("\\|", "|").replaceAll("\\.", ".");
    }
    return result;
}

export const getStateChangerRequest = (specification: string): StateModifierRequest => {
    for (const def of stateModifierMap) {
        const result = def.regex.exec(specification);
        if (result) {
            return { modifier: def.modifier, params: removeProtect(result.groups || {}) };
        }
    };
    return { modifier: null, params: {} };
}
