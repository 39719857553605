import { get } from "lodash";
import { ValidityTree } from "../../../models/metadata.model";
import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { getAllValidityPathsInTree, getValidityPathLabel } from "../../../utils/validity.utils";
import { ArraySource } from "../../ArraySource";

/***
 * Returns a matrix containing the validity tree in the input source item.
 * Each row consists of a colon-separated list of values, where each value
 * is a level in the tree.
 * Values which are the same as in the previous row are marked with the metadata
 * definition [other.repeated=true].
 * 
 * Params:
 *      store: the name of the store to load the validity tree from.
 */
export class ValidityMatrixSource implements ArraySource {

    public async getValue(sharedState: SharedState, params: { [name: string]: string }): Promise<Array<SourceItem>> {
        const validityTree: ValidityTree = sharedState.storedItems?.[params.store]?.validity || {};
        const result: Array<SourceItem> = [];

        // Build an array of all validity values in the validity tree.
        const allPaths: Array<string> = getAllValidityPathsInTree(validityTree);
        const previousRow: Array<string> = [];
        for (let path of allPaths) {
            const childItems: Array<SourceItem> = [];
            const pathArray: Array<string> = path.split(":");
            for (let i = 0; i < pathArray.length; i++) {
                if (!pathArray[i].startsWith("$")) { // Skip preview validities
                    childItems.push({
                        value: pathArray[i],
                        props: {
                            info: {
                                "name": [getValidityPathLabel(pathArray.slice(0, i + 1), sharedState.site?.validities || null)],
                                "isrepeat": [pathArray[i] === previousRow[i]],
                            }
                        },
                        state: sharedState,
                        position: i,
                    });
                }

            }
            previousRow.length = 0;
            previousRow.push(...pathArray);
            result.push({
                value: "",
                props: {},
                state: sharedState,
                children: childItems,
            });
        }
        return result;
    }
}