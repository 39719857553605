import { SharedState } from "../../sharedState/SharedState";
import { StateModifier } from "../StateModifier";

/***
 * Returns a modified SharedState.
 * 
 *  * Params:
 *          filter: the new validity filter
 */
export class ValidityStateModifier implements StateModifier {
    public modify(sharedState: SharedState, params: { [filter: string]: string }): SharedState {
        const nextSharedState: SharedState = sharedState.clone();
        nextSharedState.filters.validity = params.filter || "";
        return nextSharedState;
    }
}

export const validityStateModifier = new ValidityStateModifier();