import { SharedState, urlQueryKeys } from "../sharedState/SharedState";

/***
 * Removes specified parameters from the URL.
 * Each suppressable parameter is represented by a single character, defined
 * in urlQueryKeys.
 * The suppress argument is a string of characters, each representing a parameter
 * to suppress.
 */
export function suppressUrlParameters(state: SharedState, suppress: string): SharedState {
    const linkedState = Object.assign(new SharedState(), state);
    if (suppress === "*") {
        linkedState.filters.system = {};
        linkedState.filters.validity = "";
        linkedState.filters.custom = {};
        linkedState.filters.text = null;
        linkedState.diffBase = "";
        linkedState.displayProperties = {};
        linkedState.filters.searchOptions.order = [];
        linkedState.organization = null;
        linkedState.configuration = null;
    }
    else {
        for (let spec of suppress) {
            switch (spec) {
                case urlQueryKeys.systemProperties:
                    linkedState.filters.system = {};
                    break;
                case urlQueryKeys.validity:
                    linkedState.filters.validity = "";
                    break;
                case urlQueryKeys.customProperties:
                    linkedState.filters.custom = {};
                    break;
                case urlQueryKeys.textFilter:
                    linkedState.filters.text = null;
                    break;
                case urlQueryKeys.diffBase:
                    linkedState.diffBase = "";
                    break;
                case urlQueryKeys.displayProperties:
                    linkedState.displayProperties = {};
                    break;
                case urlQueryKeys.sortOrder:
                    linkedState.filters.searchOptions.order = [];
                    break;
                case urlQueryKeys.organization:
                    linkedState.organization = null;
                    break;
                case urlQueryKeys.configuration:
                    linkedState.configuration = null;
                    break;
            }
        }
    }
    return linkedState;
}

/***
 * Get the relative path from a file to the root of the site.
 */
export function getPathToRoot(path: string) {
    const hasLeadingSlash = path.startsWith("/");
    const levels = Math.max(0, path.split("/").length - (hasLeadingSlash ? 2 : 1)); // Remove the leading / and the file name
    return "./" + "../".repeat(levels);
}