import { PropertyValue } from "../models/metadata.model";
import { SearchOrder } from "../models/search-options.model";
import { SharedState, urlQueryKeys } from "./SharedState";

/***
 * Format:
 * One or more of (&-separated):
 * <key>.<name>=<values>
 * wkere key is defined in urlQueryKeys
 * 
 * values is either a single value, or a colon-separated list of values
 * 
 * Example:
 * 
 * s.lang=en&c.role=operator:installer&c.process=troubleshooting&v.release=1.0|1.1
 */
export class QueryFormat {
    public static parse(params: string): SharedState {
        const result: SharedState = new SharedState();
        const items = params.split(";");
        for (let item of items) {
            if (item.startsWith("slot=")) {
                result.filters.slot = item.substring(item.indexOf("=") + 1);
            }
            else if (item.startsWith(urlQueryKeys.organization + "=")) {
                result.organization = item.substring(item.indexOf("=") + 1);
            }
            else if (item.startsWith(urlQueryKeys.configuration + "=")) {
                result.configuration = item.substring(item.indexOf("=") + 1);
            }
            else if (item.startsWith(urlQueryKeys.sortOrder + "=")) {
                const orders: Array<string> = (item.substring(2)).split(":");
                const orderList: Array<SearchOrder> = [];
                for (let order of orders) {
                    const parts: Array<string> = order.split(".");
                    if (parts.length === 3 && (parts[2] === "1" || parts[2] === "-1")) {
                        orderList.push({ propertyType: parts[0], propertyName: parts[1], direction: parts[2] })
                    }
                }
                if (orderList.length) {
                    result.filters.searchOptions.order = orderList;
                }
            }
            else if (item.includes("=") && item.length > item.indexOf("=") + 1) {
                const key = item[0];
                const valuePart = item.substring(item.indexOf("=") + 1);
                const values = item.substring(item.indexOf("=") + 1).split(":");
                if (key === urlQueryKeys.textFilter) {
                    result.filters.text = values[0];
                }
                else if (key === urlQueryKeys.validity) {
                    result.filters.validity = valuePart;
                }
                else if (key === urlQueryKeys.diffBase) {
                    result.diffBase = valuePart;
                }
                else if (item.charAt(1) === ".") {
                    const name = item.substring(2, item.indexOf("="));
                    switch (key) {
                        case urlQueryKeys.systemProperties:
                            result.filters.system[name] || (result.filters.system[name] = []);
                            result.filters.system[name].push(...this.parseValues(values));
                            break;
                        case urlQueryKeys.customProperties:
                            result.filters.custom[name] || (result.filters.custom[name] = []);
                            result.filters.custom[name].push(...this.parseValues(values));
                            break;
                        case urlQueryKeys.displayProperties:
                            result.displayProperties[name] || (result.displayProperties[name] = []);
                            result.displayProperties[name].push(...this.parseValues(values));
                            break;
                    }
                }
            }
        }
        return result;
    }

    public static stringify(state: SharedState): string {
        let parts: Array<string> = [];


        if (state.filters.slot) {
            parts.push("slot=" + state.filters.slot);
        }
        if (state.filters.validity) {
            parts.push(urlQueryKeys.validity + "=" + state.filters.validity);
        }
        if (state.diffBase) {
            parts.push(urlQueryKeys.diffBase + "=" + state.diffBase);
        }
        for (let name in state.filters.system) {
            // Include the system property in the link unless it was added as a default value
            if (!state.defaultSystemProps.name || state.defaultSystemProps.name !== state.filters.system[name]) {
                parts.push(urlQueryKeys.systemProperties + "." + name + "=" + QueryFormat.stringifyArray(state.filters.system[name], ":"));
            }
        }
        for (let name in state.filters.custom) {
            // Include the custom property in the link unless it was added as a default value
            if (!state.defaultCustomProps.name || state.defaultCustomProps.name !== state.filters.custom[name]) {
                parts.push(urlQueryKeys.customProperties + "." + name + "=" + QueryFormat.stringifyArray(state.filters.custom[name], ":"));
            }
        }
        for (let name in state.displayProperties) {
            parts.push(urlQueryKeys.displayProperties + "." + name + "=" + QueryFormat.stringifyArray(state.displayProperties[name], ":"));
        }
        if (state.filters.text) {
            parts.push(urlQueryKeys.textFilter + "=" + state.filters.text);
        }
        if (state.organization) {
            parts.push(urlQueryKeys.organization + "=" + state.organization);
        }
        if (state.configuration) {
            parts.push(urlQueryKeys.configuration + "=" + state.configuration);
        }
        if (state.filters.searchOptions?.order && state.filters.searchOptions?.order.length && !state.defaultSearchOptions.includes("order")) {
            let orderArray: Array<string> = [];
            for (let orderDef of state.filters.searchOptions?.order) {
                orderArray.push(`${orderDef.propertyType}.${orderDef.propertyName}.${orderDef.direction}`);
            }
            parts.push(urlQueryKeys.sortOrder + "=" + QueryFormat.stringifyArray(orderArray, ":"));
        }

        return QueryFormat.stringifyArray(parts, ";");
    }

    private static parseValues(array: Array<string>): Array<PropertyValue> {
        const returned: Array<PropertyValue> = [];
        for (let value of array) {
            const separatorIx = value.indexOf('|');
            const stringValue: string = separatorIx === -1 ? value : value.substring(0, separatorIx);
            const valueType: string = separatorIx === -1 ? "" : value.substring(separatorIx + 1);
            switch (valueType) {
                case "number":
                    returned.push(parseInt(stringValue));
                    break;
                case "boolean":
                    returned.push(stringValue.toLowerCase() === "true");
                    break;
                case "date":
                    returned.push(Date.parse(stringValue));
                    break;
                case "string":
                default:
                    returned.push(stringValue);
                    break;
            }
        }
        return returned;
    }

    private static stringifyArray(array: Array<PropertyValue>, delimiter: string): string {
        let result = "";
        for (let i = 0; i < array.length; i++) {
            i !== 0 && (result += delimiter);
            if (typeof array[i] === "number") {
                result += `${array[i].toString()}|number`;
            }
            else if (typeof array[i] === "boolean") {
                result += `${array[i].toString()}|boolean`;
            }
            else if (array[i] instanceof Date) {
                result += `${(array[i] as Date).getMilliseconds()}|date`;
            }
            else if (typeof array[i] === "string") {
                result += array[i];
            }
        }
        return result;
    }

}