import { SharedState } from "../../sharedState/SharedState";
import { StateModifier } from "../StateModifier";

/***
 * Returns a modified SharedState.
 * 
 *  * Params:
 *          filter: the new baseline filter
 */
export class BaselineStateModifier implements StateModifier {
    public modify(sharedState: SharedState, params: { [name: string]: string }): SharedState {
        const nextSharedState: SharedState = sharedState.clone();
        nextSharedState.diffBase = params.filter || "";
        return nextSharedState;
    }
}

export const baselineStateModifier = new BaselineStateModifier();