import { ValidityTree } from "../models/metadata.model";
import { ValidityDefinition } from "../models/site.model";

export function validityTreeIsEmpty(validity: ValidityTree): boolean {
    return Object.keys(validity).length === 0;
}

/***
 * Add a validity to a validity tree.
 * Mutates the tree.
 */
export function addValidityToTree(
    tree: ValidityTree,
    add: string
): ValidityTree {
    const treeRoot = tree;
    const addArray = add.split(":");
    for (let value of addArray) {
        let branch = tree[value];
        if (!branch) {
            branch = {};
            tree[value] = branch;
        }
        tree = branch;
    }
    return treeRoot;
}

/***
 * In the validity tree, user defined values are used as keys.
 * To avoid problems with special characters, the keys are protected.
 */
export function protectValidityKeys(validity: string): string {
    return validity.replace("\\", "\\\\").replace(/\./g, "\\[dot]").replace(/\$/g, "\\[dollar]");
}

/***
 * Protect all keys in a validity tree.
 */
export function protectValidityTree(tree: ValidityTree): ValidityTree {
    if (!tree) {
        return {};
    }
    for (let key in tree) {
        const protectedKey = protectValidityKeys(key);
        tree[protectedKey] = protectValidityTree(tree[key]);
        if (protectedKey !== key) {
            delete tree[key];
        }
    }
    return tree;
}

/***
 * Unprotect validity keys in a string.
 */
export function unprotectValidityKeys(validity: string): string {
    return validity.replace("\\[dot]", ".").replace("\\[dollar]", "$").replace("\\\\", "\\");
}

/***
 * Unprotect all keys in a validity tree.
 */
export function unprotectValidityTree(tree: ValidityTree): ValidityTree {
    if (!tree) {
        return {};
    }
    for (let key in tree) {
        const unprotectedKey = unprotectValidityKeys(key);
        tree[unprotectedKey] = unprotectValidityTree(tree[key]);
        if (unprotectedKey !== key) {
            delete tree[key];
        }
    }
    return tree;
}


/***
 * Returns the complete database key for a validity string.
 * The validity is a colon-separated list of values.
 * The key is a dot-separated list of values, as used in the database.
 */
export function getValidityDeepKey(validity: string): string {
    let deepkey: string = "";
    const validityArray = validity.split(":");
    for (let value of validityArray) {
        deepkey += protectValidityKeys(value) + ".";
    }
    deepkey = deepkey.slice(0, -1);
    return deepkey;
}

/***
 * Returns a query object for a validity string.
 * The query object is used to search for metadata records that contain the validity.
 */
export function getValidityQuery(validity: string): object {
    const query: any = {};
    if (validity) {
        query["validityTree." + getValidityDeepKey(validity)] = { $exists: true };
    }
    return query;
}

/***
 * Check if a validity tree contains a specific validity.
 * The validity is a colon-separated list of values.
 * The tree is a tree structure where each level is a key in the tree.
 * The function returns true if the tree contains the validity.
 * The function returns false if the tree does not contain the validity.
 * 
 * Example:
 * tree = {
 *    "software": {
 *       "topic-finder": {
 *         "1.0": {}
 *       },
 *       "skribenta": {
 *          "2.0": {}
 *       }
 *    }
 * }
 * 
 * validity = "software:skribenta:2.0"
 *  
 * The function returns true.
 * 
 * validity = "software:skribenta:3.0"
 * 
 * The function returns false.
 */
export function treeContainsValidity(
    tree: ValidityTree,
    validity: string
): boolean {
    if (!validity) {
        return true;
    }
    const validityArray = validity.split(":");
    for (let value of validityArray) {
        const branch = tree[value];
        if (branch) {
            if (validityArray.length === 1) {
                return true;
            }
            else {
                if (treeContainsValidity(branch, validityArray.slice(1).join(":"))) {
                    return true;
                }
            }
        }
    }
    return false;
}

/***
 * Get the valueset for a specific validity in a validity tree.
 * The validity is a colon-separated list of values.
 * The valueset is the set of values that are defined at the level of the validity.
 * The function returns an array of strings with the values.
 */
export function getValidityValuesetFromTree(
    tree: ValidityTree,
    validity: string
): Array<string> {
    let branch = tree;
    const validityArray = validity.split(":");
    for (let value of validityArray) {
        branch = branch[value];
        if (!branch) {
            return [];
        }
    }
    return Object.keys(branch);
}

/***
 * Remove a specific validity from a validity tree
 */
export function deleteValidityFromTree(
    tree: ValidityTree,
    remove: string,
): boolean {
    const removeArray = remove.split(":");
    for (let value of removeArray) {
        const branch = tree[value];
        if (branch) {
            if (removeArray.length === 1) {
                delete tree[value];
                return true;
            }
            else {
                if (deleteValidityFromTree(branch, removeArray.slice(1).join(":"))) {
                    if (Object.keys(branch).length === 0) {
                        delete tree[value];
                    }
                    return true;
                }
            }
        }
    }
    return false;
}

/***
 * Add default values to a validity string.
 * The definition is a tree structure. The default value is used if the validity
 * string does not contain a value at a certain level.
 */
export function addDefaultValidity(validity: string, definition: ValidityDefinition | null): string {
    const validityArray = validity.split(":");
    const thisLevel = validityArray[0];
    const thisLevelResolved: string = thisLevel || definition?.default || "";
    const pos: number = definition?.values?.findIndex?.((item) => item.value === thisLevelResolved) ?? -1;
    let remainingResolved: string = "";
    if (validityArray.length > 1 || pos !== -1) {
        remainingResolved = addDefaultValidity(validityArray.slice(1).join(":"), definition?.values?.[pos]?.definition || null);
    }
    return `${thisLevelResolved}${(thisLevelResolved && remainingResolved) ? ":" : ""}${remainingResolved}`;
}

/***
 * Get all validity paths in a validity tree.
 * The paths are returned as an array of strings.
 * Each string is a colon-separated list of values.
 */
export function getAllValidityPathsInTree(tree: ValidityTree): Array<string> {
    const result: Array<string> = [];
    for (let key in tree) {
        const childPaths = getAllValidityPathsInTree(tree[key]);
        if (childPaths.length > 0) {
            for (let path of childPaths) {
                result.push(`${key}:${path}`);
            }
        }
        else {
            result.push(key);
        }
    }
    return result;
}

/***
 * Get the label for a path in a validity tree.
 * The label is the name that is defined in the validity definition that
 * corresponds to the path.
 */
export function getValidityPathLabel(path: Array<string>, definition: ValidityDefinition | null): string {
    let label = "";
    for (let value of path) {
        label = definition?.name || "";
        const pos: number = definition?.values?.findIndex((item) => item.value === value) ?? -1;
        definition = definition?.values?.[pos]?.definition || null;
    }
    return label;
}