import { ValidityDefinition } from "../../../models/site.model";
import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns an array containing the current validity path, up to and including the first
 * node that does not have a value. For each item in the array, the following information
 * is provided:
 * - value: the value of the last position in validity path, if any.
 * - props.info.path: the validity path.
 * - props.info.name: the name of the last position in the validity path.
 * 
 * Example
 * ---------
 * Suppose the validity specification indicates the validity steps:
 * 1. division
 * 2. product family
 * 3. model
 * 
 * If the current validity path is "appliances:dishwashers", the result will be
 * 
 * [
 *   { value: "appliances", props: { info: { path: "", name: "division" } } }
 *   { value: "dishwashers", props: { info: { path: "appliances", name: "product family" } } },
 *   { value: "", props: { info: { path: "appliances.product family", name: "model" } } },
 * ]
 */
export class ValidityPathSource implements ArraySource {

    public async getValue(sharedState: SharedState, params: { [name: string]: string }): Promise<Array<SourceItem>> {
        const result: Array<SourceItem> = [];
        let definition: ValidityDefinition | null = sharedState.site?.validities || null;
        const currentValidity: Array<string> = (sharedState.filters.validity || "").split(":");
        const path: Array<string> = [];
        if (currentValidity[0]?.startsWith("$")) {
            currentValidity.shift();
        }

        while (definition) {
            const item: SourceItem = {
                value: currentValidity.shift() || definition.default || "",
                props: {
                    info: {
                        "path": [path.join(":")],
                        "name": [definition.name],
                    }
                },
            }
            path.push(definition.name);
            result.push(item);

            const pos: number = definition?.values?.findIndex((loopitem) => loopitem.value === item.value.toString()) ?? -1;

            definition = definition.values?.[pos]?.definition || null;
        }
        return result;
    }
}