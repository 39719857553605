import { SharedState } from "../sharedState/SharedState";
import { ArrayModifierRequest, getArrayModifierRequest } from "./ArrayModifier";
import { ValueModifierRequest, getValueModifierRequest } from "./ValueModifier";
import { getValueSourceRequest } from "./ValueSource";
import { getArraySourceRequest } from "./ArraySource";
import { getStateChangerRequest } from "./StateModifier";
import { SourceItem } from "../models/source-item.model";
import { PropertyValue } from "../models/metadata.model";

export const stateModifierRegex = /{(?<changer>[^}]*)}/;
export const valueModifierRegex = /(?<!\\)\|[^|]+/;
const arrayModifierRegex = /(?<!\\)\|[^|]+/;


/***
 * Looks through a string value for source specifications and replaces them with the value of the source.
 * A source specification starts with $ and ends with ; (or the end of the string).
 * For instance, "$location.url" would return the current URL.
 * 
 * "$filter.customProperty.release.value;" would return the value of the release custom property in the current filter.
 * 
 * Inside an Iterator, the iteration source can be invoked to get the iterated items. For instance, while iterating
 * over all custom property names in the current filter set, the following specification returns the value of each
 * custom property:
 * "$filter.customProperty.$iterator.value;.value"
 * 
 * A value source can be followed by a formatter. Formatters are separated from the source by a pipe character (|).
 * Formatters can also be use on their own, without a source. Maximum one such formatter can be used in the same
 * string. For instance, the following specification returns a link to the path /hello-world.html:
 * "hello-world.html|link.download.."
 * 
 * Expressions can be freely nested.
 */

export function resolveValue(sharedState: SharedState, spec: string | undefined | null, hasLaterProcessing: boolean): string {
    let str: string = spec || "";
    let debug = 0;

    if (spec?.startsWith("?")) {
        str = spec.slice(1);
        debug = 1;
    }

    const stateModifierResult = runStateModifiers(sharedState, str, !hasLaterProcessing);
    sharedState = stateModifierResult.state;
    str = stateModifierResult.str;

    const exprRegex = /(?<!\\)\$[^$]*?((?<!\\);|$)/; // Match expressions which do not contain other expressions
    let match = exprRegex.exec(str);
    while (match) {
        debug && console.log("Source value match", match);
        const endsWithSemicolon = match[0].endsWith(";");
        const specification = str.slice(match.index + 1, match.index + match[0].length - (endsWithSemicolon ? 1 : 0));
        str = str.slice(0, match.index) + processPart(sharedState, specification, true, debug) + str.slice(match.index + match[0].length + (endsWithSemicolon ? 0 : 1));
        match = exprRegex.exec(str);
    }
    if (!hasLaterProcessing) {
        str = processPart(sharedState, str || "", false, debug);
        str = unprotectString(str);
    }
    debug && console.log("Source value", spec, str);

    return debug ? "?" + str : str;
}

function processPart(sharedState: SharedState, specification: string, isExpression: boolean, debug: number): string {
    let str: string = specification || "";
    let nextState = sharedState.clone();

    const valueModifierRequests: Array<ValueModifierRequest> = [];
    let valueModifierMatch;
    while ((valueModifierMatch = valueModifierRegex.exec(str)) !== null) {
        valueModifierRequests.push(getValueModifierRequest(valueModifierMatch[0].slice(1)));
        str = str.slice(0, valueModifierMatch.index) + str.slice(valueModifierMatch.index + valueModifierMatch[0].length)
    }

    let value: PropertyValue = str;
    if (isExpression) {
        const valueRequest = getValueSourceRequest(str);
        debug && console.log("Value source request", str, valueRequest);
        value = valueRequest.source?.getValue(nextState, valueRequest.params) ?? str;
    }

    for (const valueModifierRequest of valueModifierRequests) {
        if (valueModifierRequest.modifier) {
            value = valueModifierRequest.modifier.modify(nextState, value, valueModifierRequest.params);
        }
    }

    return isExpression ? protectString(value.toString()) : value.toString();
}

export function protectString(str: string): string {
    return str.replaceAll("$", "\\$").replaceAll(";", "\\;").replaceAll("|", "\\|").replaceAll(".", "\\.").replaceAll("#", "\\#"); // Escape control characters
}

export function unprotectString(str: string): string {
    return str.replaceAll("\\$", "$").replaceAll("\\;", ";").replaceAll("\\|", "|").replaceAll("\\.", ".").replaceAll("\\#", "#"); // Remove escaping of control characters
}


/***
 * Loads an array of SourceItems from a string specification by finding the specified array source and invoking it.
 */
export async function loadSourceArray(sharedState: SharedState, spec: string | undefined | null): Promise<Array<SourceItem>> {
    let str: string = spec || "";

    let debug = 0;
    // By adding a ? first in the specification, it is possible to turn on some debug logging.
    // This is useful when trying to figure out why a source specification does not work as expected.
    // This is a very primitive way of turning on debug logging, but it sort of works for now.
    if (str?.startsWith("?")) {
        str = str.slice(1);
        debug = 1;
    }

    // Remove the leading ¤ character if present. This is a remnant from the old syntax.
    // Once we know all ¤ in style sheets have been removed, we can remove this.
    if (str.startsWith("¤")) {
        str = str.slice(1);
    }

    const stateModifierResult = runStateModifiers(sharedState, str, true);
    sharedState = stateModifierResult.state;
    str = stateModifierResult.str;

    const arrayModifierRequests: Array<ArrayModifierRequest> = [];
    let arrayModifierMatch;
    while ((arrayModifierMatch = arrayModifierRegex.exec(str)) !== null) {
        arrayModifierRequests.push(getArrayModifierRequest(arrayModifierMatch[0].slice(1)));
        str = str.slice(0, arrayModifierMatch.index) + str.slice(arrayModifierMatch.index + arrayModifierMatch[0].length)
    }

    const arrayRequest = getArraySourceRequest(str);
    debug && console.log("Array source request", str, arrayRequest);
    if (arrayRequest.source) {
        let array = await arrayRequest.source.getValue(sharedState, arrayRequest.params);
        for (const filterRequest of arrayModifierRequests) {
            if (filterRequest.modifier) {
                array = filterRequest.modifier.modify(sharedState, array, filterRequest.params);
            }
        }
        debug && console.log("Source array", spec, array);
        return array;
    }
    debug && console.log("Source array", spec, []);
    return [];
}

function runStateModifiers(sharedState: SharedState, str: string, removeAfterUse: boolean): { state: SharedState, str: string } {
    let nextState = sharedState.clone();
    let stateModifierMatch;
    let reducedStr = str;
    while ((stateModifierMatch = stateModifierRegex.exec(reducedStr)) !== null) {
        let specification = resolveValue(sharedState, stateModifierMatch[0].slice(1, stateModifierMatch[0].length - 1), true);
        const stateModifierRequest = getStateChangerRequest(specification);
        if (stateModifierRequest.modifier) {
            nextState = stateModifierRequest.modifier.modify(nextState, stateModifierRequest.params);
        }
        reducedStr = reducedStr.slice(0, stateModifierMatch.index) + reducedStr.slice(stateModifierMatch.index + stateModifierMatch[0].length)
    }
    return { state: nextState, str: removeAfterUse ? reducedStr : str };
}