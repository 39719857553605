import { SourceItem } from "../../../models/source-item.model";
import { SharedState } from "../../../sharedState/SharedState";
import { ArraySource } from "../../ArraySource";

/***
 * Returns all existing values of a validity property, after applying the current filters.
 * The validity property is removed from the filters before the search is performed.
 * A validity path is used to specify the property. The path is a colon-separated list of
 * property names, of to but not including the property to get the values for.
 * 
 * For example, assume the validity definition is:
 * {
 *    name: "division", values: {
 *       "appliances": {
 *          name: "product", values: {}
 *       }
 *   }
 * }
 * 
 * If the specification is "", the values returned will be all values of the property "division".
 * 
 * If the specification is "appliances", the values returned will be all values of the
 * property "product".
 * 
 * Values starting with a dollar sign ($) are not included in the result.
 * 
 * Params:
 *    specification: The validity path specification.
 */
export class QueryValidityValuesSource implements ArraySource {
    public async getValue(sharedState: SharedState, params: { [specification: string]: string }) {
        const filters = { ...sharedState.filters };
        filters.validity = "";
        const valueItems: Array<SourceItem> = sharedState.site?.name ?
            await sharedState.backend.getPropertyValueSet(sharedState.site.name,
                sharedState.organization, sharedState.configuration, filters, params.specification, true, false)
            : [];
        return valueItems.filter((item, ix) => { return !item.value.toString().startsWith("$") }).map((item, ix) => {
            return { ...item, state: sharedState, position: ix + 1 };
        });
    }
}